import React, { useState, useEffect } from "react";
import person from "../../icons/person.svg";
import backicon from "../../icons/backIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createpost, PostchangeStatus } from "../../app/features/post/post";

export const Postvideo = ({ contents }) => {
  const toastId = React.useRef(null);

  const alert = (message, type, close) => {
    toastId.current = toast(`${message}`, {
      type: `${type}`,
      autoClose: close,
    });
  };

  const dismissalert = () => {
    toast.dismiss(toastId.current);
  };

  const photo = useSelector((state) => state.comprofile.profilephoto);
  const token = useSelector((state) => state.register.refreshtoken);
  const firstname = useSelector((state) => state.profile.firstname);
  const lastname = useSelector((state) => state.profile.lastname);
  const nickname = useSelector((state) => state.profile.nickname);
  const userid = useSelector((state) => state.register.userID);
  const poststatus = useSelector((state) => state.post.poststatus);
  const dispatch = useDispatch();

  const [content, setcontent] = useState(contents);
  const [photolink, setphotlink] = useState();
  const [imgsrc, setimgsrc] = useState();
  const [showimage, setshowimage] = useState(false);
  const [propics, setpropics] = useState(person);
  const [username, setusername] = useState("");

  useEffect(() => {
    if (photo) {
      setpropics(photo);
    }
    if (nickname) {
      setusername(nickname);
    } else {
      let names = `${firstname} ${lastname}`;
      setusername(names);
    }
  });

  useEffect(() => {
    if (poststatus === "succeeded") {
      toast.dismiss();
      dispatch(PostchangeStatus("idle"));
      toast.success("Post upload successful", { autoClose: 5000 });
    }

    if (poststatus === "failed") {
      toast.dismiss();
      dispatch(PostchangeStatus("idle"));
    }
  }, [poststatus]);

  const postcontent = async () => {
    if (!photolink) {
      toast.error("please choose a video to post", { autoClose: false });
      return;
    }
    alert("uploading video post, do not cancel", "info", false);

    if ((await handleChange(photolink)) >= 65) {
      dismissalert();
      toast.error("Video  must not be grater than 1min", { autoClose: 2000 });
      return;
    }

    if (poststatus === "idle") {
      dispatch(
        createpost({ userid, photolink, token, content, posttype: "video" })
      );
    }
  };

  const getVideoDuration = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(media.duration);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => reject(error);
    });

  const handleChange = async (file) => {
    let length = await getVideoDuration(file);

    return length;
  };

  return (
    <div className="mx-auto scroll-pr-3">
      <div className="flex flex-col pt-1 pb-1 h-fit">
        <div className="flex">
          <button
            onClick={(e) => {
              toast.dismiss();
            }}
          >
            <img alt="baclIcon" src={backicon} className="mb-2"></img>
          </button>

          <p className="text-slate-200 mb-2">Post</p>
        </div>

        <div className="flex pl-1 pt-1">
          <div className="w-fit h-fit bg-slate-500 rounded-full">
            <img
              alt="post image"
              src={propics}
              className="w-10 h-10 object-cover rounded-full"
            ></img>
          </div>
          <p className="ml-2 font-semibold text-slate-200">{username}</p>
        </div>

        {showimage && (
          <video
            className="object-cover w-1/2 mx-auto rounded-xl mt-1"
            alt="post image"
            src={imgsrc}
            controls
            controlsList="nodownload"
          ></video>
        )}

        <input
          type="file"
          className="mt-2 text-yellow-500"
          accept="video/*"
          onChange={async (e) => {
            setshowimage(true);

            if (e.target.files[0]) {
              setimgsrc(URL.createObjectURL(e.target.files[0]));
              setphotlink(e.target.files[0]);
            }
          }}
        ></input>
        <div className="flex mt-2 flex-col ">
          <textarea
            className="textinpt placeholder:mt-2 w-2/3 mx-auto"
            placeholder="What is on your mind?"
            rows={5}
            value={content}
            onInput={(e) => {
              setcontent(e.currentTarget.value);
            }}
          ></textarea>
          <button
            className="btn mt-2 rounded-xl"
            onClick={async (e) => {
              postcontent();
            }}
          >
            Post
          </button>
        </div>
      </div>
    </div>
  );
};
